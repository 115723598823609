import { MultiSelect } from "@mantine/core";
import { useEffect, useState } from "react";
import { labelStyle } from "../../functions/styleFunctions";

const EditableMulti = ({ edit, srcs, onInputChange, selectedSources }) => {
  const [sources, setSources] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (srcs) setSources(sterilizeSources(srcs));
    if (selectedSources && selectedSources[0] != null) {
      const sterilizedSelectedSources = sterilizeSources(selectedSources);
      setSelectedItems(sterilizedSelectedSources);
      setSelectedValues(
        sterilizedSelectedSources.map((s) => s.value.toString())
      );
    }
  }, [srcs, selectedSources]);

  const handleSourceChange = (values) => {
    const newSelectedItems = srcs.filter((v) => values.includes(v.source_id));
    onInputChange(newSelectedItems);
  };

  const sterilizeSources = (data) => {
    return data.map((source) => ({
      color: source.source_color,
      textc: source.source_text_color,
      label: source.source_name,
      value: source.source_id,
      linkid: source.linkid ? source.linkid : 0,
    }));
  };

  return (
    <>
      {!edit &&
        selectedItems &&
        selectedItems.map(
          (cs) =>
            cs && (
              <div key={cs.linkid} style={labelStyle(cs.color, cs.textc)}>
                <small>via</small> {cs.label}
              </div>
            )
        )}
      {edit && sources && (
        <MultiSelect
          style={{ marginTop: 20 }}
          data={sources}
          label="Client Sources"
          placeholder="Select sources"
          searchable
          clearable
          multiple
          value={selectedValues && selectedValues}
          onChange={(values) => handleSourceChange(values)}
        />
      )}
    </>
  );
};

export default EditableMulti;
