import { Alert, Card, Text, Grid, Col, Paper } from "@mantine/core";
import { useEffect } from "react";
import { AlertCircle } from "tabler-icons-react";
import jsonData from "../../functions/call.json";

const AdminCallLog = () => {
  console.log(jsonData);
  // useEffect(() => {}, [])
  const firstCallId = jsonData.data[0].call_id;
  console.log(firstCallId);

  return (
    <>
      <Alert
        icon={<AlertCircle size="2rem" />}
        title="Drīzumā!"
        color="green"
        style={{ marginBottom: 15 }}
      >
        Work in progress.
      </Alert>
      {jsonData.data.map((d) => {
        return <CallCard callData={d} />;
      })}
    </>
  );
};

const CallCard = ({ callData }) => {
  return (
    <Card shadow="xs" style={{ marginBottom: '20px' }}>
      <Grid gutter="sm">
        <Col span={12} xs={6}>
          <Text weight={700}>Time:</Text>
          <Text>{callData.starttime} ({callData?.duration_sec} sec)</Text>
          <Text weight={700}>Direction:</Text>
          <Text>{callData.direction}</Text>
        </Col>
        <Col span={12} xs={6}>

          <Text weight={700}>Type:</Text>
          <Text>{callData.type}</Text>
          <Text weight={700}>Status:</Text>
          <Text>{callData.status}</Text>
        </Col>
        <Col span={12} xs={6}>
          <Text weight={700}>Caller Number:</Text>
          <Text>{callData.caller.number}</Text>
          <Text weight={700}>Callee Number:</Text>
          <Text>{callData.callee.number}</Text>
        </Col>
        {callData.audio_url && (
          <Col span={12} xs={6}>
            <Paper padding="sm" shadow="xs">
              <Text weight={700}>Listen to Call:</Text>
              <audio controls>
                <source src={callData.audio_url} type="audio/ogg" />
                Your browser does not support the audio element.
              </audio>
            </Paper>
          </Col>
        )}
      </Grid>
    </Card>
  );
}

export default AdminCallLog;
