import { LockClosedIcon } from "@heroicons/react/solid";
import { useState, useEffect, useRef } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import {
  checkResetToken,
  handleLogin,
  setNewPassword,
  setPasswordResetToken,
} from "../../functions/api";
import {
  setGroup,
  setToken,
  setUserId,
  setData,
  setCallToken,
} from "../../functions/tokens";
import { useParams, Link } from "react-router-dom";
import logo from "../../assets/m_logo.png";

export default function LoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const formRef = useRef(null);

  const navigate = useNavigate();

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleRememberMe = (event) => {
    setRememberMe(event.target.checked);
  };

  useEffect(() => {
    const frmNd = formRef.current;

    const handleAnimationEnd = () => frmNd.classList.remove("shake");

    frmNd && frmNd.addEventListener("animationend", handleAnimationEnd);

    return () =>
      frmNd && frmNd.removeEventListener("animationend", handleAnimationEnd);
  }, [error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await handleLogin(email, password, rememberMe);
      if (!response.ok) {
        throw new Error("Incorrect email or password");
      }
      const data = await response.json();
      setToken(data.token);
      console.log(data);
      setCallToken(data.callToken ? data.callToken : "");
      setGroup(data.group);
      setUserId(data.id);
      setData(data);
      navigate("/main");
    } catch (err) {
      setError(err.message);
      formRef.current.classList.add("shake");
    }
  };

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-16 w-auto"
              src={logo}
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign in to your account
            </h2>
            {/* <p className="mt-2 text-center text-sm text-gray-600">
              Or{" "}
              <a
                href="#"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                start your 14-day free trial
              </a>
            </p> */}
          </div>
          <form className="mt-8 space-y-6" ref={formRef}>
            <input type="hidden" name="remember" defaultValue="true" />
            {error && (
              <div style={{ textAlign: "center", color: "red" }}>{error}</div>
            )}
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={rememberMe}
                  onChange={handleRememberMe}
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a
                  href="/forgot-password"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                onClick={handleSubmit}
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export function ForgotPasswordScreen() {
  const [email, setEmail] = useState();
  const [sent, setSent] = useState(false);

  const submitForgotPassword = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      setPasswordResetToken(email);
      setSent(true);
    } else {
      window.alert("Email is not valid!");
    }
  };

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 mt-20">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={logo}
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Reset your password
            </h2>
          </div>
          {!sent ? (
            <form className="mt-8 space-y-2">
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="-space-y-px rounded-md shadow-sm">
                <div>
                  <label htmlFor="password" className="sr-only">
                    Enter email
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="relative block w-full appearance-none rounded-none rounded-t-md rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Enter e-mail"
                  />
                </div>
              </div>
              <div>
                <button
                  onClick={submitForgotPassword}
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Reset password
                </button>
              </div>
            </form>
          ) : (
            <div>
              <button
                onClick={() => (window.location.href = "/login")}
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Done! Check your e-mail
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export function PassResetScreen() {
  const { token } = useParams();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [repeatP, setReapeatP] = useState();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const formRef = useRef(null);

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await setNewPassword({ token: token, pass: password });
      console.log(response);
      if (response.message == "win") {
        navigate("/");
      } else {
        setError("Invalid token or password. Please try again.");
      }
    } catch (error) {
      console.error(error);
      setError("Something went wrong. Please try again later.");
    }
  };

  useEffect(() => {
    checkResetToken({ token: token }).then((data) => {
      setIsLoading(false);
      if (data.error) {
        setError(data.error);
      } else {
        setUser(data);
      }
    });
  }, [token]);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 mt-20">
        {error ? (
          <div className="w-full max-w-md space-y-8">
            <div>
              <img
                className="mx-auto h-12 w-auto"
                src={logo}
                alt="Your Company"
              />
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                Error!
              </h2>
              <p className="text-center">Link might have expired</p>
              <div className="mt-6 flex justify-center">
                <Link
                  to="/login"
                  className="text-indigo-600 hover:text-indigo-500"
                >
                  Return to login
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full max-w-md space-y-8 mt-20">
            <div>
              <img
                className="mx-auto h-12 w-auto"
                src={logo}
                alt="Your Company"
              />
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                Reset your password
              </h2>
            </div>
            <form className="mt-8 space-y-2" ref={formRef}>
              <input type="hidden" name="remember" defaultValue="true" />
              {error && (
                <div style={{ textAlign: "center", color: "red" }}>{error}</div>
              )}
              <div className="rounded-md shadow-sm">
                <div>
                  <label htmlFor="password" className="sr-only">
                    New password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="current-password"
                    required
                    className="relative block w-full appearance-none rounded-none rounded-t-md rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="New password"
                  />
                </div>
              </div>

              <div className="rounded-md shadow-sm">
                <div>
                  <label htmlFor="password" className="sr-only">
                    Repeat password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={repeatP}
                    onChange={(e) => setReapeatP(e.target.value)}
                    autoComplete="current-password"
                    required
                    className="relative block w-full appearance-none rounded-none rounded-b-md rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Repeat password"
                  />
                </div>
              </div>
              <div>
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Reset password
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
}
