import React, { useState } from "react";
import {
  Button,
  Card,
  TextInput,
  Textarea,
  useMantineTheme,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { submitLead } from "../../functions/api";

const LeadScreen = () => {
  const [form, setForm] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
    email: "",
    comment: "",
    deadline: null,
  });
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const theme = useMantineTheme();

  const handleChange = (event) => {
    const { name, value } = event.target;

    // validate email field
    if (name === "email") {
      const isValidEmail = /\S+@\S+\.\S+/.test(value);
      setErrors({ ...errors, email: !isValidEmail });
    } else {
      setErrors({ ...errors, [name]: false });
    }

    setForm({ ...form, [name]: value });
  };

  const handleDeadlineChange = (value) => {
    setForm({ ...form, deadline: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // check that at least one of email or phone number fields is not empty
    if (!form.email && !form.phoneNumber) {
      setErrors({ ...errors, email: true, phoneNumber: true });
      return;
    }

    // check email field for validity
    if (form.email && errors.email) {
      return;
    }

    setSubmitting(true);

    try {
      const response = await submitLead(form);
      if (response[0].clientid) {
        setSuccess(true);
        setForm({
          name: "",
          surname: "",
          phoneNumber: "",
          email: "",
          comment: "",
          deadline: null,
        });
      } else {
        alert("Something went wrong. Please try again.");
      }
    } catch (error) {
      alert("Something went wrong. Please try again.");
    }
    setSubmitting(false);
  };

  return (
    <Card shadow="sm" padding="lg">
      {success ? (
        <div style={{ textAlign: "center" }}>
          <h3 style={{ color: theme.colors.green[6] }}>Form submitted!</h3>
          <Button
            style={{ backgroundColor: "lightgreen" }}
            variant="outline"
            onClick={() => setSuccess(false)}
          >
            Submit another form
          </Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <TextInput
            label="Name"
            placeholder="Enter your name"
            name="name"
            value={form.name}
            onChange={handleChange}
          />
          <TextInput
            label="Surname"
            placeholder="Enter your surname"
            name="surname"
            value={form.surname}
            onChange={handleChange}
          />
          <TextInput
            label="Phone number"
            placeholder="Enter your phone number"
            name="phoneNumber"
            value={form.phoneNumber}
            onChange={handleChange}
          />
          <TextInput
            label="Email"
            placeholder="Enter your email"
            name="email"
            value={form.email}
            onChange={handleChange}
            error={errors.email}
            errormessage="Please enter a valid email address"
          />
          <Textarea
            label="Comment"
            placeholder="Enter your comment"
            name="comment"
            value={form.comment}
            onChange={handleChange}
          />
          <div style={{ marginTop: "1rem" }}>
            <DateTimePicker
              label="Deadline"
              placeholder="Enter your deadline"
              name="deadline"
              value={form.deadline}
              onChange={handleDeadlineChange}
              minDate={new Date()}
              inputProps={{ autoComplete: "off" }}
            />
          </div>
          <div style={{ marginTop: "1rem" }}>
            <Button
              style={{ backgroundColor: "dodgerblue" }}
              type="submit"
              disabled={submitting}
            >
              {submitting ? "Submitting..." : "Submit"}
            </Button>
          </div>
        </form>
      )}
    </Card>
  );
};

export default LeadScreen;
