import React, { Fragment, useState } from "react";
// import { Dialog, Transition } from "@headlessui/react";
// import { XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { ExternalLink } from "tabler-icons-react";
import { Text } from "@mantine/core";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Sidebar } from "primereact/sidebar";
import PinButton from "../PinButton";

// export default function SideOverlay({ open, setOpen, data }) {

//   return (
//     <Transition.Root show={open} as={Fragment}>
//       <Dialog as="div" className="relative z-9" onClose={setOpen}>
//         <Transition.Child
//           as={Fragment}
//           enter="ease-in-out duration-500"
//           enterFrom="opacity-0"
//           enterTo="opacity-100"
//           leave="ease-in-out duration-500"
//           leaveFrom="opacity-100"
//           leaveTo="opacity-0"
//         >
//           <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
//         </Transition.Child>

//         <div className="fixed inset-0 overflow-hidden">
//           <div className="absolute inset-0 overflow-hidden">
//             <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
//               <Transition.Child
//                 as={Fragment}
//                 enter="transform transition ease-in-out duration-500 sm:duration-700"
//                 enterFrom="translate-x-full"
//                 enterTo="translate-x-0"
//                 leave="transform transition ease-in-out duration-500 sm:duration-700"
//                 leaveFrom="translate-x-0"
//                 leaveTo="translate-x-full"
//               >
//                 <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
//                   <Transition.Child
//                     as={Fragment}
//                     enter="ease-in-out duration-500"
//                     enterFrom="opacity-0"
//                     enterTo="opacity-100"
//                     leave="ease-in-out duration-500"
//                     leaveFrom="opacity-100"
//                     leaveTo="opacity-0"
//                   >
//                     <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
//                       <button
//                         type="button"
//                         className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
//                         onClick={() => setOpen(false)}
//                       >
//                         <span className="sr-only">Close panel</span>
//                         <XIcon className="h-6 w-6" aria-hidden="true" />
//                       </button>
//                     </div>
//                   </Transition.Child>
//                   <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
//                     <div className="px-4 sm:px-6">
//                       <Dialog.Title className="text-lg font-medium text-gray-900">
//                         {data && data.title}
//                       </Dialog.Title>
//                       {data && data.route && (
//                         <div>
//                           <Link
//                             to={{ pathname: data.route, query: { foo: "bar" } }}
//                             target="_blank"
//                             rel="noopener noreferrer"
//                             style={{ color: "dodgerblue" }}
//                           >
//                             <ExternalLink
//                               size={16}
//                               style={{
//                                 display: "inline-block",
//                                 paddingBottom: 1,
//                               }}
//                             />
//                             <Text
//                               style={{
//                                 display: "inline-block",
//                                 fontSize: 14,
//                                 textDecorationLine: "underline",
//                               }}
//                             >
//                               {" "}
//                               open in new tab
//                             </Text>
//                           </Link>
//                         </div>
//                       )}
//                     </div>
//                     <div className="relative mt-2 flex-1 px-4 sm:px-6">
//                       {/* Replace with your content */}
//                       {data && data.component ? (
//                         data.component
//                       ) : (
//                         <div className="absolute inset-0 px-4 sm:px-6">
//                           <div
//                             className="h-full border-2 border-dashed border-gray-200"
//                             aria-hidden="true"
//                           />
//                         </div>
//                       )}
//                       {/* /End replace */}
//                     </div>
//                   </div>
//                 </Dialog.Panel>
//               </Transition.Child>
//             </div>
//           </div>
//         </div>
//       </Dialog>
//     </Transition.Root>
//   );
// }

export default function SideOverlay({ open, setOpen, data }) {
  const customIcons = (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        {data && data.route && (
          <div style={{ marginRight: 20, marginBottom: 5 }}>
            <Link
              to={{ pathname: data.route, query: { foo: "bar" } }}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "dodgerblue" }}
            >
              <ExternalLink
                size={16}
                style={{
                  display: "inline-block",
                  paddingBottom: 1,
                }}
              />
              <Text
                style={{
                  display: "inline-block",
                  fontSize: 14,
                  textDecorationLine: "underline",
                }}
              >
                {" "}
                open in new tab
              </Text>
            </Link>
          </div>
        )}

        {data && (data.clientid || data.taskid) && (
          <div style={{ marginRight: 20 }}>
            <PinButton
              itemType={data.taskid ? "task" : "client"}
              itemId={data.taskid ? data.taskid : data.clientid}
            />
          </div>
        )}
      </div>
      {/* <button className="p-sidebar-icon p-link mr-2">
        <span className="pi pi-search" />
      </button> */}
    </React.Fragment>
  );

  return (
    <>
      <Sidebar
          pt={{
            mask: { className: 'zero-index' }
        }}
        style={{ width: 450 }}
        icons={customIcons}
        visible={open}
        position="right"
        onHide={() => setOpen(false)}
      >
        {data && (
          <h2 style={{ fontWeight: "bold", fontSize: 18 }}>{data.title}</h2>
        )}

        {data && data.component ? (
          data.component
        ) : (
          <div className="absolute inset-0 px-4 sm:px-6">
            <div
              className="h-full border-2 border-dashed border-gray-200"
              aria-hidden="true"
            />
          </div>
        )}
      </Sidebar>
    </>
  );
}
