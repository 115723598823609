import React, { useState, useEffect } from "react";
import {
  Table,
  Text,
  Input,
  Container,
  Grid,
  Col,
  Button,
  Select,
  Checkbox,
  Dialog,
  Group,
  Switch,
  Badge,
  Anchor,
} from "@mantine/core";
import {
  ArrowUpIcon,
  ArrowDownIcon,
  PencilAltIcon,
  EyeIcon,
  PhoneIcon,
  DocumentIcon,
} from "@heroicons/react/solid";
import { useDisclosure } from "@mantine/hooks";
import {
  getInvoice,
  getInvoices,
  triggerInvoicePaid,
  triggerInvoiceSend,
  triggerInvoiceVoid,
  updateInvoice,
} from "../../functions/api";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { DateInput } from "@mantine/dates";
import SideOverlay from "../../Components/Overlays/SideOverlay";
import AddInvoiceScreen from "./AddInvoiceScreen";
import PDFscreen, { PDFdoc, returnPDFdoc } from "./PDFscreen";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { getInvoiceStart } from "../../functions/misc";
import { Link } from "react-router-dom";
import { ExternalLink } from "tabler-icons-react";
import { getGroup } from "../../functions/tokens";
import { CallButton } from "../../Components/CallButton";
import hasAccess from "../../functions/access";
import { Comment } from "../../Components/Comment";
const showPDF = (data) => {
  //alert("implement PDF");
  PDFscreen(data);
};

function InvoicesPage() {
  const [invoices, setInvoices] = useState([]);
  const [sortColumn, setSortColumn] = useState("created_on");
  const [sortDirection, setSortDirection] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sidemodal, setSidemodal] = useState(false);
  const [payStatus, setPayStatus] = useState("unpaid");
  const [delayed, setDelayed] = useState(true);
  const [dialogData, setDialogData] = useState();

  const [opened, { toggle, close }] = useDisclosure(false);

  const openDialog = (data) => {
    setDialogData(data);
    toggle();
  };

  useEffect(() => {
    // retrieve invoices from the backend API
    const fetchInvoices = async () => {
      const result = await getInvoices({
        search: searchTerm,
        payStatus: payStatus,
        delayed: delayed,
      });
      console.log(result);
      setInvoices(result);
    };
    fetchInvoices();
    console.log("called!");
  }, [searchTerm, selectedInvoice, sidemodal, isModalOpen, payStatus, delayed]);

  const handleInvoiceClick = (invoice) => {
    setSelectedInvoice(invoice);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedInvoice(null);
    setIsModalOpen(false);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("desc");
    }
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  let sortedInvoices = [];
  if (invoices && invoices.length > 0) {
    sortedInvoices = invoices.sort((a, b) => {
      // determine sort order based on current sort column and direction
      const direction = sortDirection === "asc" ? 1 : -1;
      if (sortColumn === "issued_to") {
        return a.issued_to.localeCompare(b.issued_to) * direction;
      }
      if (sortColumn === "invoiced_for") {
        return a.invoiced_for.localeCompare(b.invoiced_for) * direction;
      }
      if (sortColumn === "issued_by") {
        return a.issued_by.localeCompare(b.issued_by) * direction;
      }
      if (sortColumn === "total_vat") {
        return (a.total_vat - b.total_vat) * direction;
      }
      if (sortColumn === "paid_amount") {
        return (a.paid_amount - b.paid_amount) * direction;
      }
      if (sortColumn === "due_date") {
        return (new Date(a.due_date) - new Date(b.due_date)) * direction;
      }
      if (sortColumn === "invoice_name") {
        return (
          (getInvoiceStart(a.created_on) +
            a.invoice_id +
            a.made_for -
            (getInvoiceStart(b.created_on) + b.invoice_id + b.made_for)) *
          direction
        );
      }
      // default to sorting by created_on date
      return (new Date(a.created_on) - new Date(b.created_on)) * direction;
    });
  }

  const sortIndicator = (column) => {
    if (column !== sortColumn) {
      return null;
    }
    return sortDirection === "asc" ? (
      <ArrowUpIcon style={{ display: "inline" }} className="w-3 h-3 ml-1" />
    ) : (
      <ArrowDownIcon style={{ display: "inline" }} className="w-3 h-3 ml-1" />
    );
  };

  return (
    <Container size="lg" style={{ maxWidth: "100%" }}>
      <SideOverlay
        open={sidemodal}
        setOpen={setSidemodal}
        data={{ component: <AddInvoiceScreen />, route: "/invoices/new" }}
      />
      <Dialog
        position={{ top: "50%", left: "50%" }}
        opened={opened}
        withCloseButton
        onClose={close}
        size="lg"
        radius="md"
      >
        <div style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
          <Text size="sm" mb="xs" weight={500}>
            Zvanīt: {dialogData && dialogData.c_phone}
          </Text>
          <CallButton
            client={
              dialogData && {
                ...dialogData,
                c_name: dialogData.issued_to,
                c_surname: "",
              }
            }
          />
        </div>
      </Dialog>
      <Grid gutter="lg" style={{ marginBottom: "1rem" }}>
        <Col span={6}>
          <Input
            placeholder="Search invoices..."
            value={searchTerm}
            onChange={handleSearchTermChange}
            variant="default"
            size="sm"
          />
        </Col>
        <Col span={4}>
          <div
            style={{
              padding: 5,
              border: "1px solid lightgrey",
              textAlign: "center",
              borderRadius: 10,
            }}
          >
            <Select
              style={{ flex: "1 1 250px" }}
              label="Invoice status"
              placeholder="Select status"
              data={[
                { value: "all", label: "visi" },
                { value: "paid", label: "paid" },
                { value: "unpaid", label: "unpaid" },
                { value: "sent", label: "sent" },
                { value: "draft", label: "draft" },
                { value: "imported", label: "imported" },
                { value: "void", label: "Annulēts" },
              ]}
              value={payStatus}
              onChange={(event) => setPayStatus(event)}
            />
            {/* <Switch
              style={{ marginTop: 10 }}
              checked={delayed}
              onChange={(event) => setDelayed(event.currentTarget.checked)}
              label="Rādīt rēķinus ar aizritējušu datumu"
            /> */}
          </div>
        </Col>
        <Col span={2}>
          <Button
            style={{ backgroundColor: "dodgerblue" }}
            onClick={() => setSidemodal(true)}
            mr={3}
          >
            New Invoice
          </Button>
        </Col>
      </Grid>
      <div style={{ overflowY: "auto" }}>
        <Table>
          <thead>
            <tr>
              <th
                onClick={() => handleSort("issued_to")}
                style={{ width: "15%" }}
              >
                Client {sortIndicator("issued_to")}
              </th>
              <th
                onClick={() => handleSort("invoice_name")}
                style={{ width: "13%" }}
              >
                Invoice name {sortIndicator("invoice_name")}
              </th>
              <th
                onClick={() => handleSort("invoiced_for")}
                style={{ width: "13%" }}
              >
                Credentials {sortIndicator("invoiced_for")}
              </th>
              <th
                onClick={() => handleSort("issued_by")}
                style={{ width: "15%" }}
              >
                Issued By {sortIndicator("issued_by")}
              </th>
              <th
                onClick={() => handleSort("created_on")}
                style={{ width: "10%" }}
              >
                Created {sortIndicator("created_on")}
              </th>
              <th
                onClick={() => handleSort("due_date")}
                style={{ width: "7%" }}
              >
                Due {sortIndicator("due_date")}
              </th>
              <th
                onClick={() => handleSort("total_vat")}
                style={{ width: "7%" }}
              >
                Total {sortIndicator("total_vat")}
              </th>
              <th
                onClick={() => handleSort("paid_amount")}
                style={{ width: "7%" }}
              >
                Paid {sortIndicator("paid_amount")}
              </th>
              <th style={{ width: "5%" }}>Status</th>
              <th style={{ width: "5%" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedInvoices &&
              sortedInvoices.map((invoice) => (
                <tr key={invoice.invoice_id}>
                  <td>
                    {invoice.issued_to}
                    <br />
                    <span style={{ fontSize: 12, color: "grey" }}>
                      {invoice.c_bussinessid}
                    </span>
                  </td>
                  <td>{invoice.invoice_name}</td>
                  <td>{invoice.invoiced_for}</td>
                  <td>{invoice.issued_by}</td>
                  <td>{new Date(invoice.created_on).toLocaleDateString()}</td>
                  <td>
                    {new Date(
                      invoice.extended_due
                        ? invoice.extended_due
                        : invoice.due_date
                    ).toLocaleDateString()}
                  </td>
                  <td>{invoice.total_vat}</td>
                  <td>{invoice.paid_amount}</td>
                  <td>{invoice.status}</td>
                  <td style={{ display: "flex" }}>
                    <a
                      onClick={() => handleInvoiceClick(invoice)}
                      style={{
                        cursor: "pointer",
                        color: "dodgerblue",
                        display: "inline",
                        marginRight: 10,
                      }}
                      title="Edit"
                    >
                      <PencilAltIcon className="h-5" />
                    </a>
                    <a
                      onClick={() =>
                        window.open("/invoices/" + invoice.invoice_id, "_blank")
                      }
                      style={{
                        cursor: "pointer",
                        color: "limegreen",
                        display: "inline",
                        marginRight: 10,
                      }}
                      title="View more info"
                    >
                      <EyeIcon className="h-5" />
                    </a>
                    {invoice.c_phone && (
                      <a
                        onClick={() => openDialog(invoice)}
                        style={{
                          cursor: "pointer",
                          color: "orange",
                          display: "inline",
                          marginRight: 10,
                        }}
                        title="Call client"
                      >
                        <PhoneIcon className="h-5" />
                      </a>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      {sortedInvoices.length === 0 && (
        <Text align="center">No matching invoices found</Text>
      )}
      {selectedInvoice && (
        <ModifyInvoiceModal
          isModalOpen={isModalOpen}
          callBack={closeModal}
          selectedInvoice={selectedInvoice}
        />
      )}
    </Container>
  );
}

export const ModifyInvoiceModal = ({
  isModalOpen,
  selectedInvoice,
  callBack,
}) => {
  const [newPaidAmount, setNewPaidAmount] = useState("");
  const [extended, setExtended] = useState(null);
  const [status, setStatus] = useState();
  const [tmpSts, setTmpsts] = useState();
  console.log(selectedInvoice);
  let group = getGroup();
  group = group == null ? 10 : group;
  const callSend = async () => {
    if (window.confirm("Are you sure you want to send to client?")) {
      setStatus("wait");
      getInvoice({ invoice_id: selectedInvoice.invoice_id }).then(
        async (dx) => {
          try {
            const pdfBlob = await pdf(returnPDFdoc(dx)).toBlob();
            const ret = await triggerInvoiceSend(
              pdfBlob,
              selectedInvoice.invoice_id
            );

            if ((await ret.message) == "WIN") {
              setStatus("sent");
              setTmpsts("send-win");
            }
          } catch (e) {
            console.log(e);
          }
        }
      );
    }
  };

  const callPaid = () => {
    if (hasAccess(group, "markInvoices", "edit")) {
      if (
        window.confirm(
          "Are you want to mark this paid in full amount of " +
            selectedInvoice.total_vat +
            "?"
        )
      ) {
        triggerInvoicePaid(selectedInvoice.invoice_id).then(() =>
          setStatus("paid")
        );
      }
    }
  };

  const callVoid = () => {
    if (window.confirm("Vai esi drošs, ka vēlies annulēt šo rēķinu?")) {
      triggerInvoiceVoid(selectedInvoice.invoice_id);
      setStatus("void");
    }
  };

  const handlePaidAmountSave = (id) => {
    updateInvoice({
      extended_due: extended,
      paid_amount: newPaidAmount,
      id: id,
      status: status,
    });
    callBack(false);
  };

  useEffect(() => {
    if (selectedInvoice.paid_amount > 0) {
      setNewPaidAmount(selectedInvoice.paid_amount);
    }
    if (selectedInvoice.extended_due != null) {
      setExtended(new Date(selectedInvoice.extended_due));
    }
    setStatus(selectedInvoice.status);
  }, []);

  const handleModalClose = () => {
    callBack(false);
    //setNewPaidAmount("");
  };

  const handlePaidAmountChange = (event) => {
    setNewPaidAmount(event.target.value);
  };

  return (
    <Modal isOpen={isModalOpen} onClose={handleModalClose}>
      <ModalOverlay style={{ backgroundColor: "#00000080" }} />
      <ModalContent
        style={{
          backgroundColor: "white",
          padding: 20,
          margin: "5%",
          marginTop: "10%",
        }}
      >
        <ModalHeader>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <>Edit Invoice: {selectedInvoice.invoice_name}</>{" "}
            {selectedInvoice.status == "void" &&
              hasAccess(group, "markInvoices", "delete") && (
                <Badge color={"red"} variant="filled">
                  Annulēts
                </Badge>
              )}
          </div>
        </ModalHeader>
        {selectedInvoice.made_for && (
          <Link
            to={{
              pathname: `/partner/${selectedInvoice.made_for}`,
              query: { foo: "bar" },
            }}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "dodgerblue" }}
          >
            <ExternalLink
              size={16}
              style={{
                display: "inline-block",
                paddingBottom: 1,
              }}
            />
            <Text
              style={{
                display: "inline-block",
                fontSize: 14,
                textDecorationLine: "underline",
              }}
            >
              {" "}
              View client in new tab
            </Text>
          </Link>
        )}
        <ModalBody>
          <Grid gutter="lg">
            <Col span={6}>
              <Text fontWeight="bold">Issued To:</Text>
              <Input
                value={selectedInvoice.issued_to}
                disabled
                variant="default"
                size="sm"
              />
            </Col>
            <Col span={6}>
              <Text fontWeight="bold">Invoiced For:</Text>
              <Input
                value={selectedInvoice.invoiced_for}
                disabled
                variant="default"
                size="sm"
              />
            </Col>
            <Col span={6}>
              <Text fontWeight="bold">Issued By:</Text>
              <Input
                value={selectedInvoice.issued_by}
                disabled
                variant="default"
                size="sm"
              />
            </Col>
            <Col span={6}>
              <Text fontWeight="bold">Total VAT:</Text>
              <Input
                value={selectedInvoice.total_vat}
                disabled
                variant="default"
                size="sm"
              />
            </Col>
            <Col span={6}>
              <Text fontWeight="bold">Paid Amount:</Text>
              <Input
                value={newPaidAmount}
                onChange={handlePaidAmountChange}
                variant="default"
                size="sm"
                disabled={
                  selectedInvoice.paid_date
                    ? true
                    : hasAccess(group, "markInvoices", "edit")
                    ? false
                    : true
                }
              />
            </Col>
            <Col span={6}>
              <Text fontWeight="bold">
                Deadline extended to: (temp. disabled)
              </Text>
              <DateInput
                minDate={new Date()}
                value={extended}
                onChange={setExtended}
                disabled
                disabledx={selectedInvoice.paid_date ? true : false}
              />
            </Col>
            <Col span={12}>
              {status && status != "paid" ? (
                <>
                  {" "}
                  Actions
                  <div style={{ paddingTop: 15 }}>
                    {status != "void" && (
                      <Button
                        onClick={() => callVoid(selectedInvoice)}
                        style={{ backgroundColor: "red", marginRight: 10 }}
                      >
                        Annulēt
                      </Button>
                    )}
                    {status &&
                      status == "draft" &&
                      (selectedInvoice?.client?.c_email ||
                      selectedInvoice?.c_email ? (
                        <Button
                          style={{ backgroundColor: "dodgerblue" }}
                          onClick={() => callSend(selectedInvoice)}
                          mr={3}
                        >
                          Send to client
                        </Button>
                      ) : (
                        <p style={{ fontWeight: "bold" }}>
                          Sūtīšanai pievienojiet klienta kartiņā e-pastu!
                        </p>
                      ))}
                    {status && tmpSts == "" && status == "sent" && (
                      <Button
                        style={{ backgroundColor: "dodgerblue" }}
                        onClick={() => callPaid(selectedInvoice)}
                        mr={3}
                      >
                        Mark as Paid
                      </Button>
                    )}
                    {status && tmpSts == "send-win" && (
                      <div
                        style={{
                          backgroundColor: "lightgrey",
                          padding: 10,
                          color: "green",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        Successfully sent!
                      </div>
                    )}
                    {status && status == "wait" && (
                      <div
                        style={{
                          backgroundColor: "lightgrey",
                          padding: 10,
                          color: "black",
                        }}
                      >
                        {" "}
                        Sending...
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  Invoice paid & closed at:{" "}
                  {selectedInvoice.paid_date &&
                    new Date(selectedInvoice.paid_date).toLocaleString(
                      "en-US",
                      {
                        dateStyle: "short",
                        timeStyle: "short",
                      }
                    )}
                </>
              )}
            </Col>
          </Grid>
        </ModalBody>
        <ModalFooter
          style={{
            marginTop: 15,
          }}
        >
          <Anchor
            href={"/invoices/" + selectedInvoice.invoice_id}
            target="_blank"
            style={{ marginRight: 15 }}
          >
            Pievienot komentāru
          </Anchor>
          <PDFDownloadLink
            document={<PDFdoc id={selectedInvoice.invoice_id} />}
            style={{
              cursor: "pointer",
              color: "orange",
              display: "inline",
            }}
            fileName={"INV" + selectedInvoice.invoice_name + "invoice.pdf"}
          >
            {({ blob, url, loading, error }) =>
              !loading && (
                <Button style={{ backgroundColor: "green" }} mr={3}>
                  View PDF
                </Button>
              )
            }
          </PDFDownloadLink>
          {!selectedInvoice.paid_date && (
            <Button
              style={{ backgroundColor: "dodgerblue" }}
              onClick={() => handlePaidAmountSave(selectedInvoice.invoice_id)}
              mr={3}
            >
              Save
            </Button>
          )}
          <Button onClick={handleModalClose} variant="ghost">
            Cancel
          </Button>
        </ModalFooter>
        <div style={{ marginTop: 20 }}>
          {selectedInvoice &&
            selectedInvoice.comments &&
            selectedInvoice.comments[0].comment != null &&
            selectedInvoice.comments.map((x) => (
              <Comment
                postedAt={x?.created}
                body={x?.comment}
                author={x?.name + " " + x?.surname}
              />
            ))}
        </div>
      </ModalContent>
    </Modal>
  );
};

export const InvoiceListItem = () => {};

export default InvoicesPage;
