import {
  Card,
  Text,
  Badge,
  Button,
  Grid,
  Modal,
  Textarea,
} from "@mantine/core";
import { format } from "date-fns";
import { UserIcon, CashIcon, CreditCardIcon } from "@heroicons/react/solid";
import { CreditCard, BuildingBank, ExternalLink } from "tabler-icons-react";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  SubmitComment,
  getInvoice,
  triggerInvoicePaid,
  triggerInvoiceSend,
} from "../../functions/api";
import { ModifyInvoiceModal } from "./InvoicesPage";
import PinButton from "../../Components/PinButton";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { PDFdoc, returnPDFdoc } from "./PDFscreen";
import { getGroup } from "../../functions/tokens";
import { CallButton } from "../../Components/CallButton";
import hasAccess from "../../functions/access";
import { useDisclosure } from "@mantine/hooks";
import { Comment } from "../../Components/Comment";

const InvoicePage = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState();
  const [open, setOpen] = useState(false);
  const [opened, { toggle, close }] = useDisclosure(false);
  const [comment, setComment] = useState();

  useEffect(() => {
    getInvoice({ invoice_id: id }).then((data) => {
      setInvoice(data);
    });
  }, [open]);

  if (!invoice) {
    return <div>Loading...</div>;
  }

  const {
    created_on,
    due_date,
    total_novat,
    total_vat,
    services_json,
    status,
    credentials,
    client_json,
    user,
    vat_perc,
    vat_value,
    client,
    extended_due,
    paid_amount,
  } = invoice;

  const createdDate = format(new Date(created_on), "dd.MM.yyyy");
  const dueDate = format(new Date(due_date), "dd.MM.yyyy");
  const extendedDate = extended_due
    ? format(new Date(extended_due), "dd.MM.yyyy")
    : null;
  const bankName = credentials.bank_name;
  const bankAccount = credentials.bank_account;

  const services = JSON.parse(services_json);
  // const serviceNames = services.map((service) => service.name).join(", ");
  const serviceCosts = services.map((service) => service.cost);
  const subtotal = serviceCosts.reduce((a, b) => Number(a) + Number(b), 0);

  let group = getGroup();
  group = group == null ? 10 : group;

  const submitComment = () => {
    SubmitComment({
      comment: comment,
      clientid: client.clientid,
      invoiceid: invoice.invoice_id,
    });
    close();
    setComment("");
  };

  const renderPaymentMethodIcon = (method) => {
    switch (method) {
      case "Credit Card":
        return <CreditCard size={24} color="gray" />;
      case "Bank Transfer":
        return <BuildingBank size={24} color="gray" />;
      default:
        return null;
    }
  };

  const callSend = async () => {
    if (window.confirm("Are you sure you want to send to client?")) {
      getInvoice({ invoice_id: id }).then(async (dx) => {
        try {
          const pdfBlob = await pdf(returnPDFdoc(dx)).toBlob();
          const ret = await triggerInvoiceSend(pdfBlob, id);

          if ((await ret.message) == "WIN") {
            window.location.reload();
          }
        } catch (e) {
          console.log(e);
        }
      });
    }
  };

  const callPaid = () => {
    if (hasAccess(group, "markInvoices", "edit")) {
      if (
        window.confirm(
          "Are you want to mark this paid in full amount of " + total_vat + "?"
        )
      ) {
        triggerInvoicePaid(id).then(() => window.location.reload());
      }
    }
  };

  return (
    <Card shadow="sm" radius="lg">
      <ModifyInvoiceModal
        isModalOpen={open}
        callBack={setOpen}
        selectedInvoice={invoice}
      />
      <Modal
        opened={opened}
        onClose={close}
        title="Pievienot komentāru"
        yOffset="30vh"
      >
        <Textarea
          value={comment}
          onChange={(text) => setComment(text.target.value)}
        />
        <Button
          style={{ marginTop: 20 }}
          onClick={submitComment}
          variant="outline"
        >
          Pievienot komentāru
        </Button>
      </Modal>
      <div className="flex items-center justify-between mb-4">
        <PinButton itemType={"invoice"} itemId={invoice.invoice_id} />
        <div style={{ display: "flex", alignContent: "flex-end" }}>
          {invoice.status &&
            invoice.status == "draft" &&
            (invoice.client.c_email ? (
              <Button
                style={{
                  backgroundColor: "dodgerblue",
                  display: "inline-block",
                  marginRight: 30,
                }}
                onClick={() => callSend(invoice)}
                mr={3}
              >
                Send to client
              </Button>
            ) : (
              <p style={{ fontWeight: "bold" }}>
                Sūtīšanai pievienojiet klienta kartiņā e-pastu!
              </p>
            ))}

          {invoice.status &&
            invoice.status == "sent" &&
            hasAccess(group, "markInvoices", "edit") && (
              <Button
                style={{
                  backgroundColor: "dodgerblue",
                  display: "inline-block",
                  marginRight: 30,
                }}
                onClick={() => callPaid(invoice)}
                mr={3}
              >
                Mark as paid
              </Button>
            )}

          {invoice.status == "void" ? (
            <Badge color={"red"} variant="filled">
              Annulēts
            </Badge>
          ) : (
            <Button
              style={{ backgroundColor: "dodgerblue" }}
              onClick={() => setOpen(true)}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <div>
          <Link
            to={{
              pathname: `/partner/${client.clientid}`,
              query: { foo: "bar" },
            }}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "dodgerblue" }}
          >
            <ExternalLink
              size={16}
              style={{
                display: "inline-block",
                paddingBottom: 1,
              }}
            />
            <Text
              style={{
                display: "inline-block",
                fontSize: 14,
                textDecorationLine: "underline",
              }}
            >
              {" "}
              View client in new tab
            </Text>
          </Link>
          <div className="flex items-center">
            <UserIcon className="mr-2" size={24} />
            <Text>
              {client.c_name} {client.c_surname}
            </Text>
          </div>
          {/* Klienta tel.nr: {client.c_phone} */}
        </div>
        <div style={{ textAlign: "right" }}>
          JBMNJ Nr.{invoice.invoice_name}
          <Text style={{ fontSize: 14, color: "gray" }}>{createdDate}</Text>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center"></div>
        {extendedDate ? (
          <span style={{ display: "flex" }}>
            <Text style={{ marginRight: 10, textDecoration: "line-through" }}>
              {dueDate}
            </Text>
            <Text style={{}}>{extendedDate}</Text>
          </span>
        ) : (
          <Text>{dueDate}</Text>
        )}
      </div>
      <div className="mb-4">
        {invoice.status == "paid" && (
          <Text weight={500} className="mb-2">
            Paid at {invoice.paid_date}
          </Text>
        )}
        <Text weight={500} className="mb-2">
          Services:
          {/* {serviceNames} */}
        </Text>
        {serviceCosts.map((cost, index) => (
          <div key={index} className="flex justify-between">
            <Text>{services[index].name}</Text>
            <Text>{cost} EUR</Text>
          </div>
        ))}
      </div>
      <div className="flex justify-between mb-4">
        <Text>Subtotal:</Text>
        <Text>{subtotal.toFixed(2)} EUR</Text>
      </div>
      <div className="flex justify-between mb-4">
        <Text>VAT ({vat_perc}):</Text>
        <Text>{vat_value} EUR</Text>
      </div>
      <div className="flex justify-between mb-4">
        <Text weight={700}>Total:</Text>
        <Text weight={500}>{total_vat} EUR</Text>
      </div>
      {paid_amount && (
        <div className="flex justify-between mb-1">
          <Text>Already paid:</Text>
          <Text>{paid_amount} EUR</Text>
        </div>
      )}
      {paid_amount && (
        <div className="flex justify-between mb-4">
          <Text>Left:</Text>
          <Text>{total_vat - paid_amount} EUR</Text>
        </div>
      )}
      <hr />
      {client && client.c_phone && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div>Klienta tel.nr: {client.c_phone}</div>
              <div style={{ fontSize: 12 }}>{client.c_bussinessid}</div>
            </div>

            <div>
              <CallButton client={client} />
            </div>
          </div>
          <hr />
        </>
      )}
      <Grid>
        <Grid.Col xs={6}>
          <div className="flex items-center justify-between pt-5">
            <div className="flex items-center">
              {renderPaymentMethodIcon("Bank Transfer")}
              <div style={{ marginLeft: 10, fontSize: 12 }}>
                <Text>{credentials.company_name}</Text>
                <Text>Reg: {credentials.reg_nr} </Text>
                {credentials.vat && <Text>VAT: {credentials.vat}</Text>}
                <Text>
                  {bankName} ({bankAccount})
                </Text>
              </div>
            </div>
            <Text>{user}</Text>
          </div>
          <PDFDownloadLink
            document={<PDFdoc id={id} />}
            style={{
              cursor: "pointer",
              display: "inline",
            }}
            fileName={"INV" + invoice.invoice_name + "invoice.pdf"}
          >
            {({ blob, url, loading, error }) =>
              !loading && (
                <Text
                  style={{
                    padding: 10,
                    backgroundColor: "dodgerblue",
                    color: "white",
                    borderRadius: 10,
                    marginTop: 10,
                    textAlign: "center",
                  }}
                >
                  Download Invoice
                </Text>
              )
            }
          </PDFDownloadLink>
        </Grid.Col>
        <Grid.Col xs={6} style={{ padding: 20 }}>
          <Button
            variant="outline"
            color="grape"
            radius={0}
            onClick={toggle}
            style={{ marginBottom: 15 }}
          >
            Add comment to invoice
          </Button>
          {invoice.comments &&
            invoice.comments[0].created != null &&
            invoice.comments.map((cm) => (
              <Comment
                key={cm.comment_id}
                postedAt={cm.created}
                body={cm.comment}
                author={
                  cm?.name + " " + (cm?.surname != null ? cm.surname : "")
                }
              />
            ))}
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default InvoicePage;
