import { useLocation } from "react-router-dom";
import { Text, Card, Button, Textarea, Modal, Grid } from "@mantine/core";
import { inputSelectData } from "../../mockdata";
import RadioInputs from "../../Components/Inputs/RadioInputs";
import { EditableLabels } from "../../Components/Inputs/EditableLabels";
import { useEffect, useState } from "react";
import TaskList from "../../Components/TaskLine";
import { CallButton } from "../../Components/CallButton";
import {
  getClient,
  getClientCalls,
  getClientSource,
  saveClient,
  SubmitComment,
} from "../../functions/api";
import Overlay from "../../Components/Overlays/Overlay";
import PinButton from "../../Components/PinButton";
import { Comment } from "../../Components/Comment";
import SideOverlay from "../../Components/Overlays/SideOverlay";
import AddInvoiceScreen from "./AddInvoiceScreen";
import InvoiceCard from "../../Components/InvoiceCard";
import { useDisclosure } from "@mantine/hooks";

import EditableMulti from "../../Components/Inputs/EditableMulti";
import {
  Pencil,
  PhoneOutgoing,
  PhoneIncoming,
  Outbound,
  PhoneX,
  PhoneCheck,
  PhoneOff,
  ArrowNarrowRight,
  ArrowNarrowLeft,
  Phone,
} from "tabler-icons-react";
import CallCard from "../../Components/CallCard";

const buttonGroupStyle = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
};

const buttonStyle = {
  flex: 1,
  minWidth: 250,
  maxWidth: 350,
  margin: "5px 10px",
};

const flexStyle = { flex: 1, margin: 5, marginTop: 15, minWidth: 350 };

export default function PartnerScreen({ modify = false, newEntry = false }) {
  const [tasks, setTasks] = useState([]);
  const [client, setClient] = useState([]);
  const [edit, setEdit] = useState(modify);
  const [srcs, setSrcs] = useState();
  const [overlay, setOverlay] = useState(false);
  const [ovData, setOvdata] = useState();
  const [comments, setComments] = useState();
  const [sidemodal, setSidemodal] = useState(false);
  const [invoices, setInvoices] = useState();
  const [contracts, setContracts] = useState();
  const [selectedSources, setSelectedSources] = useState([]);
  const [opened, { toggle, close }] = useDisclosure(false);
  const [comment, setComment] = useState("");
  const [callLog, setCallLog] = useState([]);

  const taskOverlay = (data) => {
    setOvdata(data);
    setOverlay(true);
  };

  const location = useLocation();
  let routes = location.pathname.split("/");

  const handleEdit = async (type) => {
    setEdit(!edit);
    if (type == "save") {
      const retid = await saveClient(client, newEntry);
      window.location.replace("/partner/" + retid[0].clientid);
    }
  };

  const invoiceStack = () => {
    setSidemodal(true);
  };

  useEffect(() => {
    async function fetchClientSources() {
      const sources = await getClientSource();
      setSrcs(sources);
    }

    fetchClientSources();
  }, []);

  useEffect(() => {
    if (routes[2] == "new") {
      setEdit(true);
    } else {
      getClient(routes[2], false).then((res) => {
        setClient(res.clientInfo);
        setTasks(res.tasks);
        setComments(res.comments);
        setInvoices(res.invoices);
        getClientCalls({ clientid: routes[2] }).then((x) =>
          setCallLog(x.callLogEntries)
        );
      });
    }
  }, [edit, sidemodal, ovData, overlay]);

  useEffect(() => {
    routes[2] != "new" &&
      newEntry == false &&
      client.sources &&
      setSelectedSources(client.sources);
  }, [client]);

  function handleSourceChange(values) {
    setSelectedSources(values);
    handleInputChange("sources", values);
  }

  function handleInputChange(fieldName, newValue) {
    setClient((prevState) => ({
      ...prevState,
      [fieldName]: newValue,
    }));
  }
  const submitComment = () => {
    SubmitComment({ comment: comment, clientid: client.clientid });
    close();
    setComment("");
  };

  const displayMore = true;
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title="Pievienot komentāru"
        yOffset="30vh"
      >
        <Textarea
          value={comment}
          onChange={(text) => setComment(text.target.value)}
        />
        <Button
          style={{ marginTop: 20 }}
          onClick={submitComment}
          variant="outline"
        >
          Pievienot komentāru
        </Button>
      </Modal>
      <SideOverlay
        open={sidemodal}
        setOpen={setSidemodal}
        data={{
          component: (
            <AddInvoiceScreen
              preclient={
                client && {
                  ...client,
                  ...{ name: client.c_name, surname: client.c_surname },
                }
              }
            />
          ),
        }}
      />{" "}
      {client && client.c_closed && (
        <div
          style={{
            padding: 5,
            marginBottom: 10,
            marginTop: "-1rem",
            width: "100%",
            textAlign: "center",
            // position: "absolute",
            backgroundColor: "#8a0000",
            color: "#f1f1f1",
          }}
        >
          Client closed on: {new Date(client.c_closed).toLocaleDateString()}
          {" -  "}
          <span style={{ fontWeight: "bold" }}>{client.reason[0]}</span>
        </div>
      )}
      {client && client.clientid && (
        <PinButton itemType={"client"} itemId={client.clientid} />
      )}
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          flexWrap: "wrap",
          padding: 5,
        }}
      >
        <Overlay
          open={overlay}
          setOpen={setOverlay}
          data={ovData}
          callBack={null}
        />
        {/* {edit && newEntry == false && ( */}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
            marginBottom: -30,
          }}
        >
          {edit && newEntry == false && (
            <Button
              variant="outline"
              onClick={handleEdit}
              color="red"
              radius={0}
              style={{ width: "auto" }}
            >
              X
            </Button>
          )}
          {!edit && (
            <Button
              variant="outline"
              onClick={handleEdit}
              radius={0}
              color="#00984E"
              style={{
                // marginTop: 25,
                width: 90,
                backgroundColor: "#00984E",
                color: "white",
              }}
            >
              <Pencil
                size={18}
                strokeWidth={2}
                color={"white"}
                style={{ marginRight: 5 }}
              />
              Edit
            </Button>
          )}
        </div>
        {/* )} */}
        {/* {!edit && (
          <Button
            variant="outline"
            onClick={handleEdit}
            color="red"
            radius={0}
            style={buttonStyle}
          >
            Edit client
          </Button>
        )} */}
        <div style={flexStyle}>
          <ClientBasicData
            data={client}
            edit={edit}
            callBack={handleInputChange}
          />
          <ClientContactInfo
            data={client}
            edit={edit}
            callBack={handleInputChange}
          />
        </div>
        <div style={flexStyle}>
          <RadioInputs
            data={inputSelectData[0]}
            selected={client && client.c_type && client.c_type}
            edit={edit}
            callBack={(val) => handleInputChange("c_type", val)}
          />
          <ClientBankInfo
            data={client}
            edit={edit}
            callBack={handleInputChange}
          />
          <EditableMulti
            selectedSources={selectedSources}
            edit={edit}
            srcs={srcs}
            onInputChange={(values) => handleSourceChange(values)}
          />
        </div>
      </div>
      <hr style={{ marginTop: 10, marginBottom: 10 }} />
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          flexWrap: "wrap",
          padding: 5,
        }}
      >
        {!edit && (
          <div style={buttonGroupStyle}>
            <Button
              onClick={() => taskOverlay({ ...client, old: false })}
              variant="outline"
              color="teal"
              radius={0}
              style={buttonStyle}
            >
              New Task / Close client
            </Button>
            <Button
              variant="outline"
              onClick={invoiceStack}
              color="orange"
              radius={0}
              style={buttonStyle}
            >
              Issue Invoice
            </Button>
            <Button
              variant="outline"
              onClick={toggle}
              color="grape"
              radius={0}
              style={buttonStyle}
            >
              Add comment
            </Button>
            <Button
              disabled
              variant="outline"
              onClick={toggle}
              color="grape"
              radius={0}
              style={buttonStyle}
            >
              placeholder
            </Button>
            <CallButton client={client} />
          </div>
        )}
        {edit && (
          <Button
            fullWidth
            style={{
              backgroundColor: "dodgerblue",
              marginTop: 30,
            }}
            onClick={() => handleEdit("save")}
          >
            Save client
          </Button>
        )}
      </div>
      <Grid gutter={1}>
        <Grid.Col xs={4}>
          <div>
            {comments && comments.length > 0 && (
              <div>
                <h4
                  style={{
                    marginTop: 10,
                    fontWeight: "bold",
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  Comments
                </h4>
                <Card
                  withBorder
                  radius="md"
                  style={{
                    maxHeight: 400,
                    overflow: "hidden",
                    overflowY: "auto",
                    padding: 10,
                    margin: 5,
                  }}
                >
                  {comments.map((cm) => (
                    <Comment
                      key={cm.comment_id}
                      postedAt={cm.created}
                      body={cm.comment}
                      author={
                        cm?.name + " " + (cm?.surname != null ? cm.surname : "")
                      }
                    />
                  ))}
                </Card>
              </div>
            )}
          </div>
        </Grid.Col>

        <Grid.Col xs={4}>
          <div>
            {invoices && invoices.length > 0 && (
              <div>
                <h4
                  style={{
                    marginTop: 10,
                    fontWeight: "bold",
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  Invoices
                </h4>
                <Card
                  withBorder
                  radius="md"
                  style={{
                    maxHeight: 400,
                    overflow: "hidden",
                    overflowY: "auto",
                    padding: 10,
                    margin: 5,
                  }}
                >
                  {invoices.map((inv) => (
                    <InvoiceCard key={inv.invoice_id} invoice={inv} />
                  ))}
                </Card>
              </div>
            )}
          </div>
        </Grid.Col>

        <Grid.Col xs={4}>
          <div>
            {callLog && callLog.length > 0 && (
              <div>
                <h4
                  style={{
                    marginTop: 10,
                    fontWeight: "bold",
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  Call Log
                </h4>
                <Card
                  withBorder
                  radius="md"
                  style={{
                    maxHeight: 400,
                    overflow: "hidden",
                    overflowY: "auto",
                    padding: 10,
                    margin: 5,
                  }}
                >
                  <CallCard callLog={callLog} />
                </Card>
              </div>
            )}
          </div>
        </Grid.Col>

        <Grid.Col xs={4}>
          <div>
            {contracts && contracts.length > 0 && (
              <div>
                <h4
                  style={{
                    marginTop: 10,
                    fontWeight: "bold",
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  Līgumi
                </h4>
                <Card
                  withBorder
                  radius="md"
                  style={{
                    maxHeight: 400,
                    overflow: "hidden",
                    overflowY: "auto",
                    padding: 10,
                    margin: 5,
                  }}
                >
                  {contracts.map((inv) => (
                    <InvoiceCard key={inv.invoice_id} invoice={inv} />
                  ))}
                </Card>
              </div>
            )}
          </div>
        </Grid.Col>
      </Grid>
      {/* </div>{" "} */}
      <h3
        style={{
          marginTop: 10,
          fontWeight: "bold",
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        {newEntry == false && tasks.length > 0 && "Tasks"}
      </h3>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <TaskList
          tasks={tasks}
          onClick={taskOverlay}
          displayData={{
            showCheckbox: displayMore,
            showAsignedTo: displayMore,
            showCreatedBy: displayMore,
            showCreatedOn: displayMore,
            showDeadline: displayMore,
          }}
        />
      </div>
      <div style={{ marginBottom: 100 }}></div>
    </>
  );
}

export const ParnterScreenFields = ({
  edit = false,
  data,
  containerStyle,
  callBack,
}) => {
  return (
    <>
      <ClientBasicData
        containerStyle={{ flex: 2, marginTop: 15, minWidth: 300 }}
        data={data}
        edit={edit}
        callBack={callBack}
      />
      <ClientContactInfo
        edit={edit}
        data={data}
        containerStyle={{ flex: 2, marginTop: 15, minWidth: 300 }}
        callBack={callBack}
      />
    </>
  );
};

export const ClientBasicData = ({
  edit = false,
  data,
  containerStyle,
  callBack,
}) => {
  return (
    <>
      <div style={containerStyle}>
        <Text>Basic client data</Text>
        <Card withBorder radius="md">
          <Grid>
            <Grid.Col xs={12}>
              <EditableLabels
                edit={edit}
                label="Bussiness name"
                placeholder="SIA John"
                data={data && data.c_bussiness_name}
                withAsterisk={false}
                onInputChange={(val) => callBack("c_bussiness_name", val)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <EditableLabels
                edit={edit}
                label="Clients name"
                placeholder="John"
                data={data && data.c_name}
                withAsterisk={true}
                onInputChange={(val) => callBack("c_name", val)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <EditableLabels
                edit={edit}
                label="Clients surname"
                placeholder="Doe"
                data={data && data.c_surname}
                withAsterisk={true}
                onInputChange={(val) => callBack("c_surname", val)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <EditableLabels
                edit={edit}
                label="Bussiness ID"
                placeholder="000000"
                data={data && data.c_bussinessid}
                withAsterisk={false}
                onInputChange={(val) => callBack("c_bussinessid", val)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <EditableLabels
                edit={edit}
                label="VAT"
                placeholder="LV000000"
                data={data && data.c_vat}
                withAsterisk={false}
                type="text"
                onInputChange={(val) => callBack("c_vat", val)}
              />
            </Grid.Col>
          </Grid>
        </Card>
      </div>
    </>
  );
};

export const ClientContactInfo = ({
  edit = false,
  data,
  containerStyle,
  callBack,
}) => {
  return (
    <>
      <div style={containerStyle}>
        <Text>Contact info</Text>
        <Card withBorder radius="md">
          <Grid>
            <Grid.Col xs={6}>
              <EditableLabels
                edit={edit}
                label="E-mail"
                placeholder="janisberzins@email.li"
                data={data && data.c_email}
                withAsterisk={true}
                type="email"
                onInputChange={(val) => callBack("c_email", val)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <EditableLabels
                edit={edit}
                label="Phone number"
                placeholder="+3712345678"
                data={data && data.c_phone}
                withAsterisk={false}
                type="text"
                onInputChange={(val) => callBack("c_phone", val)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <EditableLabels
                edit={edit}
                label="Legal address"
                placeholder="John street 54, Riga, Lv"
                data={data && data.c_jur_address}
                withAsterisk={false}
                type="text"
                onInputChange={(val) => callBack("c_jur_address", val)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <EditableLabels
                edit={edit}
                label="Physical address"
                placeholder="John street 54, Riga, Lv"
                data={data && data.c_act_address}
                withAsterisk={false}
                type="text"
                onInputChange={(val) => callBack("c_act_address", val)}
              />
            </Grid.Col>
          </Grid>
        </Card>
      </div>
    </>
  );
};

export const ClientBankInfo = ({
  edit = false,
  data,
  containerStyle,
  callBack,
}) => {
  return (
    <>
      <div style={containerStyle}>
        <Text>Client Bank info</Text>
        <Card withBorder radius="md">
          <Grid>
            <Grid.Col xs={6}>
              <EditableLabels
                edit={edit}
                label="Clients Bank"
                placeholder="Swedbank"
                data={data && data.c_bank}
                type="text"
                onInputChange={(val) => callBack("c_bank", val)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <EditableLabels
                edit={edit}
                label="Bank SWIFT"
                placeholder="HABA00"
                data={data && data.c_swift}
                withAsterisk={false}
                type="text"
                onInputChange={(val) => callBack("c_swift", val)}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <EditableLabels
                edit={edit}
                label="Bank account"
                placeholder="90LV00000000"
                data={data && data.c_bank_account}
                withAsterisk={false}
                type="text"
                onInputChange={(val) => callBack("c_bank_account", val)}
              />
            </Grid.Col>
          </Grid>
        </Card>
      </div>
    </>
  );
};
