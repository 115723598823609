// import { StatsGrid } from "../Components/StatsGrid";
import SearchBox from "../../Components/SearchBox";
import { ActionBar } from "../../Components/ActionBar";
import SideOverlay from "../../Components/Overlays/SideOverlay";
import { useEffect, useState } from "react";
import { ParnterScreenFields } from "../Client/PartnerScreen";
import { getTasks, getPinned, getMissedInvoices } from "../../functions/api";
import TaskList, { TaskItem } from "../../Components/TaskLine";
import { mockdata } from "../../mockdata";
import Overlay from "../../Components/Overlays/Overlay";
import { CallButton } from "../../Components/CallButton";
import { PinBar } from "../../Components/PinComponents";
import { labelStyle } from "../../functions/styleFunctions";
import { Card } from "@mantine/core";
import InvoiceCard from "../../Components/InvoiceCard";

export default function HomeScreen() {
  const [sidemodal, setSidemodal] = useState(false);
  const [modalData, setModaldata] = useState();

  const [upcomingTasks, setUpcomingTasks] = useState([]);
  const [missedTasks, setMissedTasks] = useState([]);
  const [overlay, setOverlay] = useState(false);
  const [ovData, setOvdata] = useState();
  const [pinned, setPinned] = useState();
  const [invoices, setInvoices] = useState();

  const taskOverlay = (data) => {
    setOvdata(data);
    setOverlay(true);
  };

  const displayMore = true;

  const additionalDisplay = (displayMore) => {
    return {
      showCheckbox: displayMore,
      showAsignedTo: displayMore,
      showCreatedBy: displayMore,
      showCreatedOn: displayMore,
      showDeadline: displayMore,
    };
  };

  const handleAction = (i, type) => {
    console.log(i);
    const displayMore = false;
    i =
      type == "task"
        ? {
            ...i,
            component: (
              <>
                {/* <PinButton
                  itemType={i.taskid ? "task" : "client"}
                  itemId={i.taskid ? i.taskid : i.clientid}
                /> */}
                <ParnterScreenFields data={i} />
                {i.sources &&
                  i.sources.map(
                    (cs) =>
                      cs && (
                        <div
                          key={cs.linkid}
                          style={labelStyle(
                            cs.source_color,
                            cs.source_text_color
                          )}
                        >
                          <small>via</small> {cs.source_name}
                        </div>
                      )
                  )}
                <div style={{ marginTop: 20 }}>
                  <CallButton client={i} />
                </div>
                <div
                  style={{ marginTop: 20 }}
                  className="bg-white rounded-md shadow-lg"
                >
                  {i && (i.task_name || i.task_description) && (
                    <TaskItem
                      onClick={() => taskOverlay(i)}
                      task={i}
                      displayData={additionalDisplay(displayMore)}
                    />
                  )}
                </div>
              </>
            ),
            route: i.clientid ? "/partner/" + i.clientid : null,
          }
        : i;
    setModaldata(i);
    setSidemodal(true);
  };

  useEffect(() => {
    const loadScreen = () => {
      const now = new Date();
      getTasks().then((tasks) => {
        const upcomingTasks = tasks.filter(
          (task) => new Date(task.deadline) >= now
        );
        const missedTasks = tasks.filter(
          (task) => new Date(task.deadline) < now
        );
        setUpcomingTasks(upcomingTasks);
        setMissedTasks(missedTasks);
      });
      getPinned().then((pins) => {
        setPinned(pins);
      });
      getMissedInvoices().then((inv) => setInvoices(inv));
    };

    loadScreen();

    const interval = setInterval(() => {
      loadScreen();
    }, 60000);

    return () => clearInterval(interval);
  }, [sidemodal, overlay, ovData, modalData]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <Overlay
          open={overlay}
          setOpen={setOverlay}
          data={ovData}
          callBack={setSidemodal}
        />
        <SideOverlay open={sidemodal} setOpen={setSidemodal} data={modalData} />
        {/* <StatsGrid /> */}
        <div
          style={{
            flex: "100%",
            padding: 10,
            flexDirection: "row",
          }}
        >
          <div>
            <div style={{ maxWidth: 500 }}>
              <SearchBox onClick={(data) => handleAction(data, "task")} />
            </div>
          </div>
        </div>
        {pinned && pinned.length > 0 && <PinBar pinned={pinned} />}
        {upcomingTasks.length > 0 && (
          <TaskList
            title={"Upcoming tasks"}
            tasks={upcomingTasks}
            displayData={additionalDisplay(displayMore)}
            onClick={(data) => handleAction(data, "task")}
          />
        )}
        {missedTasks.length > 0 && (
          <TaskList
            title={"Delayed tasks"}
            tasks={missedTasks}
            displayData={additionalDisplay(displayMore)}
            onClick={(data) => handleAction(data, "task")}
          />
        )}
        <div className="px-4 py-6 sm:px-0" style={{ flex: 4, flexBasis: 300 }}>
          <ActionBar data={mockdata} action={handleAction} />
          {invoices && invoices.length > 0 && (
            <div style={{ marginTop: 20 }}>
              <span style={{ fontSize: 15, fontWeight: "bold" }}>
                Missed invoices
              </span>
              <Card
                withBorder
                radius="md"
                style={{
                  maxHeight: 400,
                  overflow: "hidden",
                  overflowY: "auto",
                  padding: 5,
                  margin: 5,
                }}
              >
                {invoices && invoices.map((inv) => (
                  <InvoiceCard key={inv.invoice_id} invoice={inv} />
                ))}
              </Card>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
