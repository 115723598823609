import React, { useEffect, useState } from "react";
import { Button, Slider, Checkbox, Tabs } from "@mantine/core";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Box } from "@chakra-ui/react";
import { getTaskCount, getUser, taskMigration } from "../../functions/api";
import AutoSelect from "../../Components/Inputs/AutoSelect";
import { DateInput, DatePicker } from "@mantine/dates";
import { makeStyles } from "@material-ui/core/styles";
import AdminUsers from "./AdminUsers";
import { getReportFilters } from "../../functions/api";
import { ApiTab } from "./ApiScreen";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  searchFilter: {
    // [theme.breakpoints.down("sm")]: {
    //   display: "flex",
    //   flexDirection: "column",
    //   alignItems: "center",
    // },
  },
  tableContainer: {
    [theme.breakpoints.down("sm")]: {
      overflowX: "auto",
    },
  },
  tableHeadCell: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
    cursor: "pointer",
  },
  tableCell: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
    cursor: "pointer",
  },
}));

const AdminScreen = () => {
  const [filters, setFilters] = useState();
  const [recheck, setRecheck] = useState();

  useEffect(() => {
    getReportFilters().then((x) => setFilters(x));
  }, []);
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const [data, setData] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Box bg="gray.100" minH="100vh" p={8}>
      <Tabs color="teal" defaultValue="first">
        <Tabs.List>
          <Tabs.Tab value="first">Pārcelt uzdevumu</Tabs.Tab>
          <Tabs.Tab value="second" color="blue">
            Numuru pārvaldība
          </Tabs.Tab>
          <Tabs.Tab value="third" color="blue">
            Lietotāju pārvaldība
          </Tabs.Tab>
          <Tabs.Tab value="seven" color="blue" disabled>
            Rekvizītu pārvaldība
          </Tabs.Tab>
          <Tabs.Tab value="four" color="green" disabled>
            Tiesības
          </Tabs.Tab>
          <Tabs.Tab value="five" color="green" disabled>
            Import
          </Tabs.Tab>
          <Tabs.Tab value="six" color="green">
            API
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="first" pt="xs">
          <TaskTransferModel />
        </Tabs.Panel>

        <Tabs.Panel value="second" pt="xs">
          <div className={classes.root}>
            <div className={classes.searchFilter}>
              <Button
                variant="contained"
                color="primary"
                disabled
                onClick={handleClickOpen}
              >
                Add number (coming soon)
              </Button>
            </div>
            <div className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeadCell}>
                      Number
                    </TableCell>
                    <TableCell className={classes.tableHeadCell}>
                      Status
                    </TableCell>
                    <TableCell className={classes.tableHeadCell}>
                      Assigned to
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.map((row) => (
                      <TableRow key={row.userid}>
                        <TableCell className={classes.tableCell}>
                          {row.username}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.surname}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </Tabs.Panel>
        <Tabs.Panel value="third" pt="xs">
          <AdminUsers />
        </Tabs.Panel>
        {/* <Tabs.Panel value="four" pt="xs">
          <AdminRights />
        </Tabs.Panel> */}
        <Tabs.Panel value="six" pt="xs">
          <ApiTab filters={filters} />
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};

const TaskTransferModel = () => {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [todata, setTodata] = useState();
  const [fromdata, setFromdata] = useState();
  const [amount, setAmount] = useState();
  const [chb, setChb] = useState(false);
  const [deadline, setDeadline] = useState();

  useEffect(() => {
    to
      ? to.userid && getTaskCount(to.userid).then((data) => setTodata(data))
      : setTodata(null);
  }, [to]);

  useEffect(() => {
    from
      ? from.userid &&
        getTaskCount(from.userid).then((data) => {
          setFromdata(data);
          setAmount(data.incomplete_task_count);
        })
      : setFromdata(null);
  }, [from]);

  const moveTasks = () => {
    const migration = {
      from: from.userid,
      to: to.userid,
      amount: amount,
      chb: chb,
      deadline: deadline,
    };
    taskMigration(migration).then((data) => {
      data.success && window.alert("Uzdevumi pārnesti!");
      data.success && window.location.reload();
    });
  };

  return (
    <>
      <div
        style={{
          flex: 1,
          minWidth: 350,
          padding: 10,
          marginTop: 0,
          marginBottom: "auto",
        }}
      >
        <AutoSelect
          title="Izvēlies lietotāju no kura pārcelt uzdevumus"
          placeholder="Meklē lietotāju"
          getData={(data) => getUser(data, true)}
          callBack={setFrom}
        />
        {fromdata && (
          <div style={{ padding: 10, backgroundColor: "#fafafa" }}>
            <div>uzdevumi: {fromdata?.total_task_count}</div>
            <div>nepabeigtie: {fromdata?.incomplete_task_count}</div>
            <div>
              ar nekavētu izpildes laiku:{" "}
              {fromdata?.upcoming_deadline_task_count}
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          flex: 1,
          minWidth: 350,
          padding: 10,
          marginTop: 0,
          marginBottom: "auto",
        }}
      >
        <AutoSelect
          title="Izvēlies lietotāju uz kuru pārcelt uzdevumus"
          placeholder="Meklē lietotāju"
          getData={(data) => getUser(data, true)}
          callBack={setTo}
        />
        {todata && (
          <div style={{ padding: 10, backgroundColor: "#fafafa" }}>
            <div>uzdevumi: {todata?.total_task_count}</div>
            <div>nepabeigtie: {todata?.incomplete_task_count}</div>
            <div>
              ar nekavētu izpildes laiku: {todata?.upcoming_deadline_task_count}
            </div>
          </div>
        )}
      </div>
      <div style={{ flex: 1, minWidth: 350, marginBottom: "auto" }}>
        {from && to && (
          <div>
            <Slider
              value={amount}
              onChange={(newValue) => setAmount(newValue)}
              defaultValue={fromdata?.incomplete_task_count}
              min={0}
              max={fromdata?.incomplete_task_count}
            />
            <div style={{ display: "flex" }}>
              <Checkbox checked={chb} onChange={() => setChb(!chb)} /> Uzstādīt
              jaunu izpildes termiņu?
            </div>
            {chb && (
              <DateInput
                value={deadline}
                onChange={setDeadline}
                label="Jaunais deadline"
              />
            )}
            <div style={{ marginTop: 15 }}>
              Pārvietot {amount} uzdevumus no {from?.name} {from?.surname} uz{" "}
              {to?.name} {to?.surname}?
              <button
                style={{
                  display: "block",
                  padding: "10px 30px",
                  backgroundColor: "dodgerblue",
                  color: "white",
                  borderRadius: 10,
                  marginTop: 10,
                }}
                onClick={moveTasks}
              >
                Pārvietot
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const NunberTab = () => {
  return <></>;
};

export default AdminScreen;
