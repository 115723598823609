import {
  CreditCard,
  BuildingBank,
  Repeat,
  ReceiptRefund,
  Receipt,
  ReceiptTax,
  Report,
  CashBanknote,
  Coin,
  CalendarPlus,
  UserPlus,
  BrandTinder,
  User,
  FileDollar,
} from "tabler-icons-react";
import AddInvoiceScreen from "./Screens/Client/AddInvoiceScreen";
import AddTaskScreen from "./Screens/Client/AddTaskScreen";
import PartnerScreen from "./Screens/Client/PartnerScreen";
import hasAccess from "./functions/access";
import { getGroup } from "./functions/tokens";
import AddSourceScreen from "./Screens/User/SourceScreen";

let group = getGroup();
group = group == null ? 10 : group;

export const mockdata = [
  hasAccess(group, "invoices") && {
    title: "Invoices",
    icon: CreditCard,
    color: "violet",
    route: "/invoices",
    nav: true,
  },
  // {
  //   title: "Transfers",
  //   icon: BuildingBank,
  //   color: "indigo",
  //   component: <TransactionScreen />,
  // },
  hasAccess(group, "invoices", ["view", "insert"]).length >= 2 && {
    title: "New Invoice",
    icon: FileDollar,
    color: "blue",
    component: <AddInvoiceScreen />,
    route: "/invoices/new",
  },
  {
    title: "New Task",
    icon: CalendarPlus,
    color: "green",
    component: <AddTaskScreen />,
    route: "/task/new",
  },
  {
    title: "New Client",
    icon: UserPlus,
    color: "teal",
    component: <PartnerScreen modify={true} newEntry={true} />,
    route: "/partner/new",
  },
  group == 0 && {
    title: "Pievienot Nozari",
    icon: Receipt,
    color: "orange",
    component: <AddSourceScreen modify={true} newEntry={true} />,
  },
  // group == 0 && {
  //   title: "Pievienot Rekvizītus",
  //   icon: Receipt,
  //   color: "yellow",
  //   component: <AddSourceScreen modify={true} newEntry={true} />,
  // },
  // { title: "New Lead", icon: BrandTinder, color: "red" },
].filter(Boolean);

export const inputSelectData = [
  {
    name: "person-type",
    title: "Client type",
    comment: null,
    selects: [
      {
        id: "physical",
        label: "Physical person",
        selected: true,
      },
      {
        id: "juridical",
        label: "Juridical person",
      },
    ],
  },
];
