import {
  createStyles,
  Card,
  Text,
  SimpleGrid,
  UnstyledButton,
} from "@mantine/core";
import { Navigate, useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    paddingBottom: 7,
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: theme.radius.md,
    height: 90,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",
    "&:hover": {
      boxShadow: `${theme.shadows.md} !important`,
      transform: "scale(1.05)",
    },
  },
}));

export function ActionBar({ action, data, title }) {
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  const items = (action) =>
    data.map((item) => (
      <UnstyledButton
        key={item.title}
        className={classes.item}
        onClick={() => {item.nav ? navigate(item.route) : action(item)}}
      >
        <item.icon color={theme.colors[item.color][6]} size={32} />
        <Text size="xs" mt={7}>
          {item.title}
        </Text>
      </UnstyledButton>
    ));

  return (
    <>
      <Text className={classes.title}>{title ? title : "Actions"}</Text>
      <Card withBorder radius="md" className={classes.card}>
        <SimpleGrid cols={2} mt="md">
          {items(action)}
        </SimpleGrid>
      </Card>
    </>
  );
}
