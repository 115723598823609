import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Checklist } from "tabler-icons-react";
import { Textarea, Select } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { Input, FormLabel } from "@chakra-ui/react";
import {
  completeAndCreateTask,
  closeTask,
  getCauses,
  getUser,
} from "../../functions/api";
import PinButton from "../PinButton";
import DropDownButton from "../DropDownButton";
import AutoSelect from "../Inputs/AutoSelect";
import { FormattedMessage } from "react-intl";

export default function Overlay({ open, setOpen, data, callBack }) {
  const cancelButtonRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [desc, setDesc] = useState("");
  const [action, setAction] = useState("done");
  const [reasons, setReasons] = useState();
  const [reason, setReason] = useState();
  const [user, setUser] = useState();

  const completeTask = () => {
    if (action == "close" && reason == null) {
      return window.alert(<FormattedMessage id="select_reason" />);
    }
    if (action == "done" && (name == null || name.length < 3)) {
      return window.alert("Lūdzu ievadi vismaz 4 simbolus uzdevuma nosaukumā");
    }
    // if (action == "done" && (desc == null || desc.length < 5)) {
    //   return window.alert("Lūdzu ievadi vismaz 6 simbolus uzdevuma aprakstā");
    // }
    console.log(data);

    const sendData = {
      ...data,
      new_deadline: selectedDate,
      new_name: name,
      new_desc: desc,
      new_comment: comment,
      action: action,
      reason: reason,
      assigned_to: user ? user.userid : null,
    };
    //check if existing task contains taskid
    console.log("---------");
    console.log(data.taskid);
    console.log("---------");

    completeAndCreateTask(sendData);
    callBack && callBack(false);
    handleClose();
  };

  const onlyClose = () => {
    if (
      window.confirm(
        "Vai esat drošs, ka vēlaties aizvērt šo uzdevumu bez jauna uzdevuma izveidošanas?"
      )
    ) {
      const sendData = {
        ...data,
      };

      closeTask(sendData);
      callBack && callBack(false);
      handleClose();
    }
  };

  const handleStateChange = (data) => {
    setAction(data);
  };

  useEffect(() => {
    getCauses().then((data) => setReasons(data));
  }, []);

  const handleClose = () => {
    setOpen(false);
    setSelectedDate(null);
    setName(null);
    setComment(null);
    setDesc(null);
    setUser(null);
    // setAction("done");
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        style={{ overflow: "visible" }}
        className="relative z-100"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                style={{ overflow: "visible" }}
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {data && data.old != false && (
                    <div className="sm:flex justify-between">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <Checklist
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          {data && data.task_name}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {data && data.task_description}
                          </p>
                        </div>
                      </div>
                      <PinButton itemType="task" itemId={data.taskid} />
                    </div>
                  )}
                  {action == "done" && (
                    <FormLabel style={{ marginTop: 20 }} fontSize="lg">
                      New task Name
                    </FormLabel>
                  )}
                  {action == "done" && (
                    <Input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      size="lg"
                      variant="filled"
                      style={{
                        padding: 10,
                        border: "solid 1px grey",
                        width: "100%",
                      }}
                    />
                  )}
                  {action == "close" && (
                    <>
                      <Select
                        placeholder="Klienta aizvēršanas iemesls"
                        data={reasons}
                        label="Izvēlies iemeslu"
                        clearable
                        style={{ width: "100%" }}
                        className="rounded-lg"
                        value={reason}
                        onChange={setReason}
                      />
                      <Textarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Comment"
                        label="Why the client is being closed?"
                        style={{ marginTop: 20 }}
                      />
                    </>
                  )}
                  {action == "done" && (
                    <>
                      <Textarea
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        label="Task description"
                        style={{ marginTop: 20 }}
                      />
                      <AutoSelect
                        title="Izvēlies lietotāju kuram nodot uzdevumu"
                        placeholder="Meklē lietotāju"
                        getData={(data) => getUser(data, true)}
                        callBack={setUser}
                      />
                      <DateTimePicker
                        label="Select date and time for next action"
                        placeholder="Select date and time"
                        minDate={new Date()}
                        value={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        popoverProps={{ withinPortal: true }}
                      />
                    </>
                  )}
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:justify-end sm:px-6">
                  {action == "done" &&
                    data &&
                    (data.task_name || data.task_description) && (
                      <button
                        style={{
                          marginRight: 15,
                          backgroundColor: "red",
                          color: "white",
                        }}
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={onlyClose}
                      >
                        Aizvērt uzdevumu
                      </button>
                    )}
                  <DropDownButton
                    onSelect={handleStateChange}
                    onClick={completeTask}
                    options={[
                      {
                        name:
                          data && data.old && data.old != false
                            ? "Complete task"
                            : "New task",
                        action: "done",
                        selected: action == "done",
                      },
                      {
                        name: "Close client",
                        action: "close",
                        selected: action == "close",
                      },
                    ]}
                  />

                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleClose}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
