import {
  Alert,
  Tabs,
  MultiSelect,
  Grid,
  Loader,
  Accordion,
  Button,
  Anchor,
  Badge,
  Card,
  Text,
  Col,
  Paper,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { AlertCircle } from "tabler-icons-react";
import { DatePickerInput } from "@mantine/dates";
import {
  getReportCalls,
  getReportClientResults,
  getReportFilters,
  getReportInvoiceResults,
  getReportTaskResults,
} from "../../functions/api";
import jsonData from "../../functions/call.json";
import CallCard from "../../Components/CallCard";

const AdminReports = () => {
  const [filters, setFilters] = useState();
  const [recheck, setRecheck] = useState();

  useEffect(() => {
    getReportFilters().then((x) => setFilters(x));
  }, []);

  return (
    <>
      <Alert
        icon={<AlertCircle size="2rem" />}
        title="Drīzumā!"
        color="green"
        style={{ marginBottom: 15 }}
      >
        Work in progress.
      </Alert>
      <Tabs keepMounted={false} defaultValue="clients">
        <Tabs.List>
          <Tabs.Tab value="clients">Clients</Tabs.Tab>
          <Tabs.Tab value="tasks">Tasks</Tabs.Tab>
          <Tabs.Tab value="calls">Calls</Tabs.Tab>
          <Tabs.Tab value="invoices">Invoices</Tabs.Tab>
          <Tabs.Tab value="users" disabled>
            Users
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="clients" pt="xs">
          <ClientsReport filters={filters && filters} />
        </Tabs.Panel>

        <Tabs.Panel value="tasks" pt="xs">
          <TaskReport filters={filters && filters} />
        </Tabs.Panel>

        <Tabs.Panel value="calls" pt="xs">
          <CallsReport filters={filters && filters} />
        </Tabs.Panel>

        <Tabs.Panel value="invoices" pt="xs">
          <InvoicesReport filters={filters && filters} />
        </Tabs.Panel>

        <Tabs.Panel value="Users" pt="xs">
          <UsersReport />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

const filter_one = [
  { value: "closed", label: "Closed" },
  { value: "active", label: "Active" },
];

const ClientsReport = ({ filters }) => {
  const [values, setValues] = useState({
    clientStatus: [],
    whoCreated: [],
    source: [],
    closedReason1: [],
    creationRange: [null, null],
    closeRange: [null, null],
    hasTask: null,
    closedReason2: [],
  });

  const [results, setResults] = useState([]);
  const [openo, setOpeno] = useState();

  const handleChange = (name) => (value) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const submitFilters = () => {
    getReportClientResults(values).then((x) => setResults(x));
  };

  return (
    <>
      <div style={{ padding: 10 }}>
        {!filters ? (
          <Loader />
        ) : (
          <Grid>
            <Grid.Col xs={6}>
              <MultiSelect
                searchable
                data={filter_one}
                label="Klienta status"
                placeholder="Select status"
                value={values.clientStatus}
                onChange={handleChange("clientStatus")}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <MultiSelect
                searchable
                data={filters?.users}
                label="Klienta izveidotājs"
                placeholder="Select status"
                value={values.whoCreated}
                onChange={handleChange("whoCreated")}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <MultiSelect
                searchable
                data={filters?.sources}
                label="Klienta nozare"
                placeholder="Select source"
                value={values.source}
                onChange={handleChange("source")}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <MultiSelect
                searchable
                data={filters?.closes}
                label="Aizvēršanas iemesls"
                placeholder="Select status"
                value={values.closedReason1}
                onChange={handleChange("closedReason1")}
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <DatePickerInput
                clearable
                type="range"
                label="Izveidošanas laiks"
                placeholder="Pick date range"
                value={values.creationRange}
                onChange={handleChange("creationRange")}
                mx="auto"
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              {/* <Select
                data={[
                  { value: null, label: " -- " },
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                label="Ir neizpildīti uzdevumi?"
                placeholder=""
                value={values.hasTask}
                onChange={handleChange("hasTask")}
              /> */}
            </Grid.Col>
            <Grid.Col xs={4}>
              <DatePickerInput
                clearable
                type="range"
                label="Aizvēršanas laiks"
                placeholder="Pick date range"
                value={values.closeRange}
                onChange={handleChange("closeRange")}
                mx="auto"
              />
            </Grid.Col>
            <Button
              style={{ backgroundColor: "dodgerblue" }}
              onClick={() => submitFilters()}
            >
              Submit
            </Button>
            <div style={{ width: "100%" }}>
              <Accordion value={openo} onChange={setOpeno}>
                {results.map((res) => (
                  <Accordion.Item key={res.month} value={res.month}>
                    <Accordion.Control>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{res.month.substring(0, 10)}</span>{" "}
                        <span>{res.count}</span>
                      </div>
                    </Accordion.Control>
                    {openo == res.month &&
                      res.data.map((d) => {
                        return (
                          <Accordion.Panel key={d.clientid}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Anchor
                                href={"partner/" + d.clientid}
                                target="_blank"
                                style={{ minWidth: "15%" }}
                              >
                                {d?.c_name} {d?.c_surname}
                              </Anchor>
                              <div style={{ width: "25%" }}>
                                {d &&
                                  d.sources &&
                                  d.sources.map(
                                    (s) =>
                                      s?.source_name && (
                                        <Badge
                                          style={{
                                            backgroundColor: s?.source_color,
                                            color: s?.source_text_color,
                                            margin: 3,
                                          }}
                                        >
                                          {s?.source_name}
                                        </Badge>
                                      )
                                  )}
                              </div>
                              {d.c_created_by && (
                                <span style={{ color: "grey", fontSize: 14 }}>
                                  created by {d?.name} {d?.surname}
                                </span>
                              )}{" "}
                              {d.c_created?.substring(0, 10)}
                            </div>
                          </Accordion.Panel>
                        );
                      })}
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </Grid>
        )}
      </div>
    </>
  );
};

const task_status = [
  { value: "closed", label: "Closed" },
  { value: "open", label: "Open" },
  { value: "completed", label: "Completed" },
];

const group_by = [
  { value: "closed", label: "Closed" },
  { value: "created", label: "Created" },
  { value: "completed", label: "Completed" },
];

const TaskReport = ({ filters }) => {
  const [results, setResults] = useState([]);
  const [openo, setOpeno] = useState();
  const [values, setValues] = useState({
    taskStatus: [],
    whoCreated: [],
    assignedTo: [],
    creationRange: [null, null],
    completionRange: [null, null],
    closedRange: [null, null],
    deadlineRange: [null, null],
    showBy: null,
  });

  const submitFilters = () => {
    getReportTaskResults(values).then((x) => setResults(x));
  };

  const handleChange = (name) => (value) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <div style={{ padding: 10 }}>
        <Grid>
          <Grid.Col xs={4}>
            <MultiSelect
              searchable
              data={task_status}
              label="Task status"
              placeholder="Select status"
              value={values.taskStatus}
              onChange={handleChange("taskStatus")}
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <MultiSelect
              searchable
              data={filters?.users}
              label="Select who created"
              placeholder="Select status"
              value={values.whoCreated}
              onChange={handleChange("whoCreated")}
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <MultiSelect
              searchable
              data={filters?.users}
              label="Assigned to"
              placeholder="Select source"
              value={values.source}
              onChange={handleChange("assignedTo")}
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <DatePickerInput
              clearable
              type="range"
              label="Creation range"
              placeholder="Pick date range"
              value={values.creationRange}
              onChange={handleChange("creationRange")}
              mx="auto"
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <DatePickerInput
              clearable
              type="range"
              label="Completion range"
              placeholder="Pick date range"
              value={values.completionRange}
              onChange={handleChange("completionRange")}
              mx="auto"
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <DatePickerInput
              clearable
              type="range"
              label="Closure range"
              placeholder="Pick date range"
              value={values.closedRange}
              onChange={handleChange("closedRange")}
              mx="auto"
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <DatePickerInput
              clearable
              type="range"
              label="Deadline range"
              placeholder="Pick date range"
              value={values.deadlineRange}
              onChange={handleChange("deadlineRange")}
              mx="auto"
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <MultiSelect
              searchable
              data={group_by}
              label="Group by"
              placeholder="Select grouping"
              value={values.showBy}
              onChange={handleChange("showBy")}
            />
          </Grid.Col>
        </Grid>
        <Button
          style={{
            backgroundColor: "dodgerblue",
            marginTop: 20,
            marginBottom: 20,
          }}
          onClick={() => submitFilters()}
        >
          Submit
        </Button>
        <Accordion value={openo} onChange={setOpeno}>
          {results.map((res) => (
            <Accordion.Item
              key={res.month ? res.month : "x"}
              value={res.month ? res.month : "x"}
            >
              <Accordion.Control>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    {res.month ? res.month.substring(0, 10) : "ungrouped"}
                  </span>{" "}
                  <span>{res.count}</span>
                </div>
              </Accordion.Control>
              {openo == (res.month ? res.month : "x") &&
                res.data.map((d) => {
                  return (
                    <Accordion.Panel key={d.taskid}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Anchor
                          href={"partner/" + d.clientid}
                          target="_blank"
                          style={{ minWidth: "15%" }}
                        >
                          {d?.task_name}
                        </Anchor>
                        {d.created_by && (
                          <span style={{ color: "grey", fontSize: 14 }}>
                            created: {d?.created_by_name}{" "}
                            {d?.created_by_surname}
                          </span>
                        )}{" "}
                        {d.created?.substring(0, 10)}
                      </div>
                    </Accordion.Panel>
                  );
                })}
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </>
  );
};

const CallsReport = ({ filters }) => {
  const [values, setValues] = useState({
    calledTo: [],
    whoCalled: [],
    callNr: [],
  });

  const [calls, setCalls] = useState([]);

  const submitFilters = () => {
    console.log(values);
    getReportCalls(values).then(({ calls }) => {
      setCalls(calls);
      console.log(calls);
    });
  };
  const handleChange = (name) => (value) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <Grid>
        <Grid.Col xs={6}>
          <MultiSelect
            searchable
            data={filters?.users}
            label="Zvanu veica izveidotājs"
            placeholder="Select caller"
            value={values.whoCalled}
            onChange={handleChange("whoCalled")} // Fix: Update the correct state key
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <MultiSelect
            searchable
            data={[]}
            label="Klients"
            placeholder="Izvēlēties klientu"
            value={values.calledTo}
            disabled
            onChange={handleChange("calledTo")} // Fix: Update the correct state key
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <MultiSelect
            label="Klienta numurs"
            data={[]}
            placeholder="Ievadi numuru"
            searchable
            creatable
            getCreateLabel={(query) => `+ ${query}`}
            onCreate={(query) => {
              const item = { value: query, label: query };
              handleChange("callNr")(item); // Fix: Call the handleChange function correctly
              return item;
            }}
          />
        </Grid.Col>
      </Grid>
      <Button
        style={{
          backgroundColor: "dodgerblue",
          marginTop: 20,
          marginBottom: 20,
        }}
        onClick={() => submitFilters()}
      >
        Submit
      </Button>
      <div style={{ paddingBottom: 20 }}> </div>
      {calls &&
        <CallCard callLog={calls} />
      }
    </>
  );
};

const invoices_status = [
  { value: "paid", label: "Paid" },
  { value: "unpaid", label: "Unpaid" },
  { value: "void", label: "Annulētās" },
];

const invoices_group_by = [
  { value: "paid", label: "paid" },
  { value: "deadline", label: "deadline" },
  { value: "created", label: "created" },
];

const InvoicesReport = ({ filters }) => {
  const [results, setResults] = useState([]);
  const [openo, setOpeno] = useState();
  const [values, setValues] = useState({
    status: [],
    createdBy: [],
    madeFor: [],
    creationRange: [null, null],
    dueDateRange: [null, null],
    paidDateRange: [null, null],
    showBy: null,
  });

  const submitFilters = () => {
    getReportInvoiceResults(values).then((x) => setResults(x));
  };

  const handleChange = (name) => (value) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <div style={{ padding: 10 }}>
        <Grid>
          <Grid.Col xs={4}>
            <MultiSelect
              searchable
              data={invoices_status}
              label="Status"
              placeholder="Select status"
              value={values?.status}
              onChange={handleChange("status")}
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <MultiSelect
              searchable
              data={filters?.users}
              label="Created By"
              placeholder="Select users"
              value={values?.createdBy}
              onChange={handleChange("createdBy")}
            />
          </Grid.Col>
          {/* <Grid.Col xs={4}>
            <MultiSelect
            searchable
              data={filters?.clients}
              label="Made For"
              placeholder="Select clients"
              value={values?.madeFor}
              onChange={handleChange("madeFor")}
            />
          </Grid.Col> */}
          <Grid.Col xs={4}>
            <DatePickerInput
              clearable
              type="range"
              label="Creation Range"
              placeholder="Pick date range"
              value={values?.creationRange}
              onChange={handleChange("creationRange")}
              mx="auto"
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <DatePickerInput
              clearable
              type="range"
              label="Due Date Range"
              placeholder="Pick date range"
              value={values?.dueDateRange}
              onChange={handleChange("dueDateRange")}
              mx="auto"
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <DatePickerInput
              clearable
              type="range"
              label="Paid Date Range"
              placeholder="Pick date range"
              value={values?.paidDateRange}
              onChange={handleChange("paidDateRange")}
              mx="auto"
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <MultiSelect
              searchable
              data={invoices_group_by}
              label="Group By"
              placeholder="Select grouping"
              value={values?.showBy}
              onChange={handleChange("showBy")}
            />
          </Grid.Col>
        </Grid>
        <Button
          style={{
            backgroundColor: "dodgerblue",
            marginTop: 20,
            marginBottom: 20,
          }}
          onClick={submitFilters}
        >
          Submit
        </Button>
        <Accordion value={openo} onChange={setOpeno}>
          {results &&
            results.map((res) => (
              <Accordion.Item
                key={res.month ? res.month : "x"}
                value={res.month ? res.month : "x"}
              >
                <Accordion.Control>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>
                      {res.month ? res.month.substring(0, 10) : "Ungrouped"}
                    </span>{" "}
                    <span>{res.count}</span>
                  </div>
                </Accordion.Control>
                {openo == (res.month ? res.month : "x") &&
                  res.data.map((d) => {
                    const isPaid = d.status === "paid";
                    const isPastDue =
                      d.status !== "paid" &&
                      d.due_date &&
                      new Date(d.due_date) < new Date();

                    const panelStyle = {
                      textDecoration:
                        d.status == "void" ? "line-through" : "inherit",
                      textDecorationColor:
                        d.status == "void" ? "grey" : "inherit",
                      backgroundColor:
                        d.status == "void"
                          ? "inherit"
                          : isPaid
                          ? "lightgreen"
                          : isPastDue
                          ? "pink"
                          : "inherit",
                    };

                    return (
                      <Accordion.Panel key={d.invoice_id} style={panelStyle}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Anchor
                            href={"invoices/" + d.invoice_id}
                            target="_blank"
                            style={{ minWidth: "15%" }}
                          >
                            {d.invoice_name}
                          </Anchor>
                          {d.created_by_name && (
                            <span style={{ color: "grey", fontSize: 14 }}>
                              By: {d.created_by_name} {d.created_by_surname}
                            </span>
                          )}{" "}
                          <Anchor
                            href={"partner/" + d.made_for}
                            target="_blank"
                            style={{ minWidth: "10%" }}
                          >
                            {d?.c_name} {d?.c_surname}
                          </Anchor>
                          <span style={{ minWidth: "10%" }}>{d?.due_date}</span>
                          {d.created_on?.substring(0, 10)}
                        </div>
                      </Accordion.Panel>
                    );
                  })}
              </Accordion.Item>
            ))}
        </Accordion>
      </div>
    </>
  );
};

const UsersReport = () => {
  return <></>;
};

// const CallCard = ({ callData }) => {
//   return (
//     <Card shadow="xs" style={{ marginBottom: "20px" }}>
//       <Grid gutter="sm">
//         <Col span={12} xs={6}>
//           <Text weight={700}>Time:</Text>
//           <Text>
//             {callData.starttime} ({callData?.duration_sec} sec)
//           </Text>
//           <Text weight={700}>Direction:</Text>
//           <Text>{callData.direction}</Text>
//         </Col>
//         <Col span={12} xs={6}>
//           <Text weight={700}>Type:</Text>
//           <Text>{callData.type}</Text>
//           <Text weight={700}>Status:</Text>
//           <Text>{callData.status}</Text>
//         </Col>
//         <Col span={12} xs={6}>
//           <Text weight={700}>Caller Number:</Text>
//           <Text>{callData.caller.number}</Text>
//           <Text weight={700}>Callee Number:</Text>
//           <Text>{callData.callee.number}</Text>
//         </Col>
//         {callData.audio_url && (
//           <Col span={12} xs={6}>
//             <Paper padding="sm" shadow="xs">
//               <Text weight={700}>Listen to Call:</Text>
//               <audio controls>
//                 <source src={callData.audio_url} type="audio/ogg" />
//                 Your browser does not support the audio element.
//               </audio>
//             </Paper>
//           </Col>
//         )}
//       </Grid>
//     </Card>
//   );
// };

export default AdminReports;
